import gql from 'graphql-tag'
import { subscriptionFields } from './subscriptionFieldsFragment'
import { CreditCardFields } from '../../user/queries/creditCardFieldsFragment'

export default gql`
  query GetSubscriptionsPage($viewerId: ID!) {
    customer(viewerId: $viewerId) {
      id
      customerType
      firstName
      lastName
      companyName
      department
      creditCard {
        ...CreditCardFields
      }
    }

    customerSubscriptions(viewerId: $viewerId) {
      edges {
        cursor
        node {
          ...SubscriptionFields
        }
      }
      pageInfo {
        hasPreviousPage
        hasNextPage
        startCursor
        endCursor
      }
    }

    customerAgreements(viewerId: $viewerId) {
      edges {
        node {
          id
          product
          productText
          productImageUrl
          pricingText
          recipientText
          orderDate
          nextPaymentDate
          endDate
          status
        }
      }
    }
  }
  ${subscriptionFields}
  ${CreditCardFields}
`
