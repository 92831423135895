import * as React from 'react'

// Small decorator which injects product data in the same manner as a Query component would.
// If we sometime get the data from API we can replace this hard-coded data decorator with a query decorator

const queryResult = {
  products: {
    edges: [
      { node: { name: 'Apu', productCode: 'AP' } },
      { node: { name: 'Apu-digilehti', productCode: 'APP' } },
      { node: { name: 'Apu Terveys', productCode: 'TR' } },
      { node: { name: 'Apu Terveys -digilehti', productCode: 'TRP' } },
      { node: { name: 'ApuKrypto', productCode: 'KY' } },
      { node: { name: 'ApuRistikot', productCode: 'AR' } },
      { node: { name: 'Avotakka', productCode: 'AT' } },
      { node: { name: 'Avotakka-digilehti', productCode: 'ATP' } },
      { node: { name: 'Demi', productCode: 'DE' } },
      { node: { name: 'Demi-digilehti', productCode: 'DEP' } },
      { node: { name: 'Eeva', productCode: 'EV' } },
      { node: { name: 'Eeva-digilehti', productCode: 'EVP' } },
      { node: { name: 'Image', productCode: 'IM' } },
      { node: { name: 'Image-digilehti', productCode: 'IMP' } },
      { node: { name: 'Kauneus & Terveys', productCode: 'KT' } },
      { node: { name: 'Kauneus & Terveys -digilehti', productCode: 'KTP' } },
      { node: { name: 'Kotivinkki', productCode: 'KV' } },
      { node: { name: 'Kotivinkki-digilehti', productCode: 'KVP' } },
      { node: { name: 'Maku', productCode: 'MK' } },
      { node: { name: 'Maku-digilehti', productCode: 'MKP' } },
      { node: { name: 'Meidän Mökki', productCode: 'MM' } },
      { node: { name: 'Meidän Mökki -digilehti', productCode: 'MMP' } },
      { node: { name: 'Meidän talo', productCode: 'MT' } },
      { node: { name: 'Meidän talo -digilehti', productCode: 'MTP' } },
      { node: { name: 'Mondo', productCode: 'MO' } },
      { node: { name: 'Mondo-digilehti', productCode: 'MOP' } },
      { node: { name: 'Trendi', productCode: 'TD' } },
      { node: { name: 'Trendi-digilehti', productCode: 'TDP' } },
      { node: { name: 'Tuulilasi', productCode: 'TL' } },
      { node: { name: 'Tuulilasi-digilehti', productCode: 'TLP' } },
      { node: { name: 'Unelmien Talo&Koti', productCode: 'TK' } },
      { node: { name: 'Unelmien Talo&Koti -digilehti', productCode: 'TKP' } },
      { node: { name: 'Viherpiha', productCode: 'VP' } },
      { node: { name: 'Viherpiha -digilehti', productCode: 'VPP' } },
      { node: { name: 'Voi Hyvin', productCode: 'VH' } },
      { node: { name: 'Voi Hyvin -digilehti', productCode: 'VHP' } }
    ]
  }
}

export default (): Function => (Component) => (props: any) => (
  <Component queryResult={queryResult} {...props} />
)
