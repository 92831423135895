const subscriptions = {
  MY_SUBSCRIPTIONS: 'Omat tilauksesi',
  NO_ACTIVE_SUBSCRIPTIONS:
    'Sinulla ei ole tällä hetkellä tilausta. Tutustu monipuoliseen valikoimaamme ja tilaa uutta luettavaa.',
  SUBSCRIPTIONS_SUBTITLE:
    'Voit myös tilata <a href="https://lehtitilaukset.a-lehdet.fi/" target="_blank">uutta luettavaa</a> tai tutkia <a href="/paattyneet-tilaukset"> päättyneitä tilauksia</a>.',
  ORDER_NEW_READING_MATERIAL: 'Tilaa uutta luettavaa',
  NEW_READING_MATERIAL: 'Uutta luettavaa',
  PAUSE_SUBSCRIPTION: 'Tauko lehtitilaukseen',
  CHANGE_PRODUCT: 'Vaihda lehti toiseen',
  CHANGE_RECEIVER: 'Vaihda lehden saaja',
  AUTO_RENEW: 'Muuta jatkuvaksi',
  IS_AUTO_RENEWED: 'Jatkuva tilaus',
  CANCEL_SUBSCRIPTION: 'Lopeta tilaus',
  CANCEL_AGREEMENT: 'Tilauksen päättäminen',
  RENEW_CTA: 'Toimi nyt, seuraava lehti ilmestyy %s!',
  RECEIVER: 'Saaja: %s',
  PERIOD: 'Jakso: %s',
  MAGAZINE_RECEIVER: 'Lehden saaja',
  NEW_MAGAZINE_RECEIVER: 'Lehden uusi saaja',
  CHANGE_RECEIVER_FLASH_BODY:
    'Saat vahvistuksen saajan vaihdosta sähköpostiisi kun asiakaspalvelumme on käsitellyt pyyntösi.',
  CHOOSE_NEW_PRODUCT: 'Valitse lehti johon haluat vaihtaa',
  CHOOSE_PRODUCT_LABEL: 'Valitse haluamasi lehti',
  ENDED_SUBSCRIPTIONS: 'Päättyneet tilaukset',
  START_DATE: 'Jakson alkupäivä: %s',
  PERIOD_START_DATE: 'Jakson alkamispäivä: %s',
  NEXT_SHIPPING_DATE: 'Seuraava lehti ilmestyy %s',
  NEXT_PAYMENT_DATE: 'Seuraava maksupäiväsi on %s',
  AGREEMENT_CONTINUOUS_SUBSCRIPTION: 'Jatkuva tilaus',
  AGREEMENT_END_DATE: 'Tilauksesi päättyy %s',
  AGREEMENT_ORDER_DATE: 'Tilaus alkanut: %s',
  CANCEL_CANCEL: 'En halua lopettaa tilausta',
  CANCEL_CANCEL_FREE_ITEM: 'Palaa alkuun',
  CANCEL_CONFIRM_PAY: 'Maksa lasku ja lopeta tilaus',
  CANCEL_CONFIRM_PAY_FREE_ITEM: 'Siirry maksamaan',
  CANCEL_CONFIRM: 'Lopeta tilaus',
  CANCEL_AFFIRM: 'Kyllä, haluan peruuttaa tilauksen',
  AGREEMENT_CANCEL_AFFIRM: 'Kyllä, haluan peruuttaa tilauksen',
  AGREEMENT_CANCEL_CANCEL: 'En halua lopettaa tilausta',
  CANCEL_PRICE_FEE_SUFFIX: '+ mahdolliset huomautus- ja paperilaskulisät',
  WHY_CANCEL_HEADING: 'Miksi lopetat tilauksen?',
  WHY_CANCEL_CONTENT:
    'Ikävää, että olet päättänyt lopettaa lehden tilaamisen. Haluaisimme kuulla, miksi aiot lopettaa tilauksesi?',
  AGREEMENT_WHY_CANCEL_HEADING: 'Miksi lopetat tilauksen?',
  AGREEMENT_WHY_CANCEL_CONTENT:
    'Ikävää, että olet päättänyt lopettaa tilaamisen. Haluaisimme kuulla, miksi aiot lopettaa tilauksesi?',
  CANCEL_PROCEED_CONTENT: 'Haluatko yhä peruuttaa tilauksesi?',
  AGREEMENT_CANCEL_PROCEED_CONTENT:
    'Oletko varma, että haluat peruuttaa tilauksesi?',
  PAUSE_DURATION: 'Tauon kesto',
  CHANGE_TO_AUTORENEW: 'Muuta tilaus jatkuvaksi',
  CONFIRM_AUTORENEW: 'Haluan muuttaa tilauksen automaattisesti jatkuvaksi',
  BILLING_AND_PREMIUMS: 'Laskutus ja tilaajalahjat',
  NEWSLETTER_SUBSCRIPTIONS: 'Uutiskirjetilaukset',
  ORDER_AGAIN: 'Tilaa uudelleen',
  ORDER_AS_GIFT: 'Tilaa lahjaksi',
  ORDER_BASE_URL: 'https://lehtitilaukset.a-lehdet.fi/',
  ORDER_MAGAZINE_URL: 'https://lehtitilaukset.a-lehdet.fi/lehtitarjous/%s',
  HOW_TO_END: 'Tilauksen lopetus',
  PRICING: 'Hinta',
  GO_BACK_TO_SUBSCRIPTION: 'Palaa tilaukseen',
  CANCEL_SUBSCRIPTION_BENEFIT_TITLE:
    'Huomaathan, että seuraavat edut lakkaavat heti irtisanomisen jälkeen',
  CANCEL_SUBSCRIPTION_BENEFIT_DIGI_TITLE: 'Digilehti',
  CANCEL_SUBSCRIPTION_BENEFIT_DIGI_BULLET_1:
    'Oikeutesi lukea digilehti päättyy välittömästi',
  CANCEL_SUBSCRIPTION_BENEFIT_DIGI_BULLET_2:
    'Et voi enää lukea aiemmin ilmestyneitä lehtiä tai hakea kiinnostavia artikkeleita digiarkistosta',
  CANCEL_SUBSCRIPTION_BENEFIT_RAFFLES_TITLE: 'Arvonnat',
  CANCEL_SUBSCRIPTION_BENEFIT_RAFFLES_BULLET_1:
    'Et ole mukana asiakkaillemme tarjottavissa vaihtuvissa asiakaseduissa',
  CANCEL_SUBSCRIPTION_BENEFIT_GIFTS_TITLE: 'Tilaajalahjat',
  CANCEL_SUBSCRIPTION_BENEFIT_GIFTS_BULLET_1:
    'Saat lunastamattoman tilaajalahjan ainoastaan, jos jatkat tilaustasi tilauskauden loppuun',
  NO_SUBSCRIPTIONS_TEXT:
    'Näyttäisi siltä, että sinulla ei ole voimassa olevia tilauksia tällä asiakasnumerolla!',
  NO_SUBSCRIPTIONS_LINKS: `
    <ul>
      <li>Haluatko tilata luettavaa? <a href="https://lehtitilaukset.a-lehdet.fi/" target="_blank">Kurkista valikoimaa ja tilaa</a>.</li>
      <li>Jos sinulla on tilauksia toisella asiakasnumerolla, vaihda asiakasnumeroasi <a href="/omat-tiedot">muokkaamalla omia tietojasi</a>.</li>
      <li>Jos haluat nähdä päättyneet tilauksesi, kurkista <a href="/paattyneet-tilaukset">tänne</a>.</li>
      <li>Jos tilauksesi ei näy, <a href="tarvitsetko-apua">lähetäthän meille viestiä</a>.
      </li>
    </ul>
  `,
  SUBSCRIPTION_RECIPIENT_ACTIONS:
    'Jos haluat tehdä muutoksia tilaukseen, niin ota yhteyttä lahjatilauksen tekijään.',
  CANCEL_REASON_EXPENSIVE_TITLE: 'En pysty maksamaan juuri nyt',
  CANCEL_REASON_EXPENSIVE_TEXT:
    'Jos tarvitset lisää maksuaikaa, kerrothan siitä meille. Voimme siirtää laskusi eräpäivää',
  CANCEL_REASON_EXPENSIVE_CTA: 'Pyydä eräpäivän siirtoa',
  CANCEL_REASON_INTERESTS_TITLE: 'Kiinnostuksen kohteeni ovat muuttuneet',
  CANCEL_REASON_INTERESTS_TEXT: 'Vaihda lehteä, saat sen samaan hintaan.',
  CANCEL_REASON_INTERESTS_CTA: 'Vaihda lehteä',
  CANCEL_REASON_NOTIME_TITLE_1: 'En ehdi lukemaan',
  CANCEL_REASON_NOTIME_TEXT_1:
    'Välillä aikaa ei jää lehdille. Antaisitko siis lopun tilauksesi lahjaksi? Näin säilytät saman hinnan ja ilahdutat jotakuta toista. Lehden saajan vaihtaminen ei aiheuta kustannuksia.',
  CANCEL_REASON_NOTIME_CTA_1: 'Vaihda lehden saajaa',
  CANCEL_REASON_NOTIME_TEXT_2:
    'Voit jättää lehden hetkeksi tauolle ja jatkaa, kun lukuaikaa taas löytyy. Näin lehden edullinen hinta pysyy myös ennallaan.',
  CANCEL_REASON_NOTIME_CTA_2: 'Jätä tilaus tauolle',
  CANCEL_REASON_CONTENT_TITLE: 'Sisältöön liittyvä syy',
  CANCEL_REASON_CONTENT_TEXT:
    'Haluaisimme kuulla, miten voisimme parantaa lehtemme sisältöä.',
  CANCEL_REASON_OTHER_TITLE: 'Muu syy, mikä?',
  CANCEL_REASON_OTHER_TEXT:
    'Kerrothan meille, miten voisimme parantaa tarjoamaamme.',
  AGREEMENT_CANCEL_REASON_EXPENSIVE_TITLE: 'En pysty maksamaan juuri nyt',
  AGREEMENT_CANCEL_REASON_EXPENSIVE_TEXT: '',
  AGREEMENT_CANCEL_REASON_INTERESTS_TITLE: 'Kiinnostuksen kohteeni ovat muuttuneet',
  AGREEMENT_CANCEL_REASON_INTERESTS_TEXT: '',
  AGREEMENT_CANCEL_REASON_NOTIME_TITLE: 'En ehdi lukemaan',
  AGREEMENT_CANCEL_REASON_NOTIME_TEXT: '',
  AGREEMENT_CANCEL_REASON_CONTENT_TITLE: 'Sisältöön liittyvä syy',
  AGREEMENT_CANCEL_REASON_CONTENT_TEXT: '',
  AGREEMENT_CANCEL_REASON_OTHER_TITLE: 'Muu syy, mikä?',
  AGREEMENT_CANCEL_REASON_OTHER_TEXT: ''
}

export default subscriptions
