import * as React from 'react'
import * as css from '$src/shared/styles/HorizontalItemGrid.css'
import { routes } from '$src/routes'
import { MdModeEdit, MdSettings } from 'react-icons/lib/md'
import { Text } from '$src/shared/content/text'
import IconPersonal from '$src/shared/icons/icon-personal.svg'
import Button from '$src/shared/components/Button'
import classnames from 'classnames'
import { Customer } from '$src/user/types/Customer'
import { Identity } from '$src/user/types/Identity'
import { customerDisplayName } from '$src/user/util'

export default ({
  customer,
  viewer,
  disabled = false
}: {
  customer?: Customer
  viewer?: Identity
  disabled?: boolean
}) => (
  <div className={css.gridItem}>
    <IconPersonal />
    <div className={css.section}>
      <h3 className={css.title}>
        <Text text="MY_INFO" />
      </h3>
      {!disabled ? (
        <p>
          {customerDisplayName(customer)}
          <br />
          {viewer && viewer.email}
          <br />
          {viewer && viewer.phoneNumber}
        </p>
      ) : (
        <p>
          {viewer && viewer.email}
          <br />
          {viewer && viewer.phoneNumber}
        </p>
      )}
    </div>
    {!disabled &&
      (customer.primaryAddress ? (
        <div className={css.section}>
          <p>
            {customer.primaryAddress.street}
            <br />
            {customer.primaryAddress.postalCode} {customer.primaryAddress.city}
          </p>
        </div>
      ) : (
        <div className={classnames(css.section, 'italic')}>Ei osoitetietoja</div>
      ))}

    <ul className={css.links}>
      <li>
        <Button disabled={disabled} transparent={true} action to={routes.userInfo()}>
          <MdModeEdit size={20} /> <Text text="EDIT_MY_INFO" />
        </Button>
      </li>
      <li>
        <Button
          action
          href={process.env.REACT_APP_IDENTITY_SERVICE_URL}
          target="_blank">
          <MdSettings size={20} /> <Text text="ACCOUNT_SETTINGS" />
        </Button>
      </li>
    </ul>
  </div>
)
