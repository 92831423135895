import * as React from 'react'
import Title from '$src/shared/components/Title'
import CancelSubscriptionReasonsForm from '../forms/CancelSubscriptionReasonsForm'
import { Subscription } from '../types/Subscription'
import getSubscription from '../queries/getSubscription'
import { query } from '$src/shared/apollo/Query'
import CancelSubscriptionOptionsForm from '../forms/CancelSubscriptionOptionsForm'
import { Text } from '$src/shared/content/text'
import { routes } from '$src/routes'
import Layout from '$src/shared/layout/Layout'
import SubscriptionProduct from '../components/SubscriptionProduct'
import { Customer } from '$src/user/types/Customer'
import FlashMessageFromRouter from '$src/shared/components/FlashMessageFromRouter'
import { withViewerContext, WithViewer } from '$src/shared/helpers/withViewer'

interface Props extends WithViewer {
  queryResult: {
    subscription: Subscription
    customer: Customer
  }
}

// Also used in the form. One more usage and I'll move it to it's own file...
export const enum Reasons {
  none = '',
  cost = 'Expensive',
  interests = 'NotInterested',
  busy = 'NoTime',
  content = 'BadContent',
  other = 'Other'
}

const enum FormStages {
  reason = 'reason',
  cancelOptions = 'cancel-options'
}

type State = {
  formStage: FormStages
  reason: Reasons
  reasonMessage: string
}

@query(getSubscription)
class CancelSubscription extends React.Component<Props, State> {
  state = {
    formStage: FormStages.reason,
    reason: Reasons.none,
    reasonMessage: ''
  }

  _scrollToEl: HTMLElement

  setReasonOption = (value) => (e) => {
    this.setState({
      reason: value
    })
  }

  setReasonMessage = (e) => {
    this.setState({
      reasonMessage: e.target.value
    })
  }

  onReasonFormCompleted = () => {
    this.setState({
      formStage: FormStages.cancelOptions
    })

    this._scrollToEl.scrollIntoView()
  }

  render() {
    const {
      viewer,
      queryResult: { subscription, customer }
    } = this.props
    const { formStage, reason, reasonMessage } = this.state

    return (
      <Layout main>
        {/* Scroll target element */}
        <span ref={(ref) => (this._scrollToEl = ref)} />
        <Title
          title={Text('CANCEL_SUBSCRIPTION')}
          breadcrumb={Text('CANCEL_SUBSCRIPTION')}
        />
        <FlashMessageFromRouter />
        <Layout white>
          <SubscriptionProduct
            subscription={subscription}
            customer={customer}
            compact
            basicOnly
          />

          {formStage === FormStages.reason && (
            <>
              <hr className="light" />
              <h4>
                <Text text="WHY_CANCEL_HEADING" />
              </h4>
              <p>
                <Text text="WHY_CANCEL_CONTENT" />
              </p>
              <CancelSubscriptionReasonsForm
                reason={reason}
                reasonMessage={reasonMessage}
                setReason={this.setReasonOption}
                setMessage={this.setReasonMessage}
                onCompleted={this.onReasonFormCompleted}
                subscription={subscription}
                customer={customer}
              />
            </>
          )}

          {formStage === FormStages.cancelOptions && (
            <CancelSubscriptionOptionsForm
              viewer={viewer}
              reason={reason}
              reasonMessage={reasonMessage}
              subscription={subscription}
              completedRoute={routes.subscriptions()}
              customer={customer}
            />
          )}
        </Layout>
      </Layout>
    )
  }
}

export default withViewerContext(CancelSubscription)
