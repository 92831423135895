import { SuspendOption } from './SuspendOption'

export enum SubscriptionRole {
  S = 'S',
  M = 'M',
  Y = 'Y'
}

export type Subscription = {
  id: string
  role?: SubscriptionRole
  productCode: string
  productTitle: string
  productImageUrl: string
  shipToName?: string
  pricingText: string
  termLengthText: string
  subscriptionStatusText: string
  processingStatusText?: string
  digitalMagazineUrl?: string
  orderDate: string
  nextShipDate: string
  periodStartDate: string
  activeInvoiceId?: string
  isShippedToBillingAddress: boolean
  isLocked: boolean
  isActive: boolean
  isDigitalMagazine: boolean
  isCreditCardCharged: boolean
  extraContent: any[]
  editOptions: Array<{ code }>
  suspendOptions?: SuspendOption[]
  freePremiumUrl?: string
}
