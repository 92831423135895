const user = {
  LOGIN_TITLE: 'Asiakaspalvelu',
  LOGIN_INGRESS:
    'Kirjaudu sisään tai luo uusi tunnus. A-tunnuksella voit kirjautua palveluihin, maksaa laskuja ja hallita tietojasi.',
  ENABLE_TEMP_ADDRESS: 'Otan tilapäisen osoitteen käyttöön',
  TITLE_GREETING_WITH_NAME: 'Tervetuloa, %s!',
  TITLE_GREETING: 'Tervetuloa!',
  GREETING_INGRESS: '',
  BEGINS: 'Alkaa',
  ENDS: 'Loppuu',
  EMAIL_MARKETING_LABEL: 'Email',
  SMS_MARKETING_LABEL: 'SMS',
  PROFILING_PERMISSION: 'Profilointilupa',
  PROFILING_LABEL:
    'Haluan suosituksia ja asiakasviestintää, joka perustuu verkossa kuluttamiini sisältöihin ja A-lehtien tilaustietoihin.',
  MARKETING_INGRESS_ONE:
    'Lisätietoja henkilötietojen käsittelystä ja evästeiden käytöstä löydät <a href="https://www.a-lehdet.fi/tietoa-meista/tietosuojaseloste" target="_blank">tietosuojaselosteesta</a> ja <a href="https://www.a-lehdet.fi/tietoa-meista/evastekaytannot" target="_blank">evästekäytännöistä</a>.',
  MARKETING_INGRESS_TWO:
    'Sinulla on myös oikeus kieltää puhelintarjonta ilmoittamalla siitä ASML:n Robinson-rekisteriin.',
  CHOOSE_PHONE_NUMBER: 'Jatkossa käytettävä puhelinnumero',
  CHOOSE_EMAIL_ADDRESS: 'Jatkossa käytettävä sähköpostiosoite',
  AUTH_EMAIL: 'Sähköpostiosoite jota käytät kirjautumiseen.',
  AUTH_PHONE: 'Puhelinnumero jota käytät kirjautumiseen.',
  EDIT_CREDENTIALS_INFO:
    'Muokkaa käyttäjätunnukseesi liitettyjä tietoja A-tunnus-palvelussa.',
  CUSTOMER_NUMBER_CONNECTED_SUCCESS: 'Asiakasnumero liitetty onnistuneesti',
  CONNECTED_TO_CUSTOMER_NUMBER: 'Liitettävässä asiakasnumerossa',
  NEW_CUSTOMER_NUMBER: 'Liitettävä asiakasnumero',
  CUSTOMER_NUMBER_OWNER_COUNTRY: 'Asiakasnumeron omistajan kotimaa',
  CUSTOMER_NUMBER_ZIP: 'Postinumero',
  CUSTOMER_NUMBER_FIRSTNAME: 'Etunimi',
  CUSTOMER_NUMBER_LASTNAME: 'Sukunimi',
  CUSTOMER_NUMBER_COUNTRY_NAME: 'Maa',
  FINLAND_DOMESTIC: 'Suomi, osoite kotimaassa',
  OTHER_INTERNATIONAL: 'Muu, osoite ulkomailla',
  CUSTOMER_NUMBER: 'Asiakasnumero',
  CUSTOMER_NUMBER_IS_MINE: 'Asiakasnumero on omani',
  ADD_CUSTOMER_NUMBER: 'Liitä asiakasnumero',
  ADD_NEW_CUSTOMER_NUMBER: 'Liitä asiakasnumero',
  CUSTOMER_NUMBER_IS_OWN_CHECKBOX:
    'Vakuutan, että asiakasnumero on omani ja käyttäjätunnukseni tiedot voi päivittää asiakasnumeron tietoihin.',
  CUSTOMER_NUMBER_IS_AUTHORIZED_CHECKBOX:
    'Vakuutan, että minulla on oikeus asioida henkilön puolesta, jolle asiakasnumero kuuluu. Tarvittaessa esitän todisteen asiasta.',
  NEWSLETTERS: 'uutiskirjeet',
  ENABLED_NEWSLETTERS: 'Tilatut uutiskirjeet',
  NEWSLETTERS_INGRESS:
    'Voit tilata uutiskirjeitä verkkosivuiltamme. Uutiskirje peruutetaan uutiskirjeessä olevan linkin kautta. Voit myös hallita markkinointilupia omista tiedoistasi.',
  CHOOSE_NEWSLETTERS: 'Valitse millaisia uutiskirjeitä saat A-lehiltä.',
  SENDING_DAY: 'Lähetyspäivä',
  EXCEPTIONALLY: 'poikkeuksellisesti',
  CLIENTSHIP_AND_BENEFITS: 'Asiakkuus ja asiakasedut',
  CLIENTSHIP_AND_BENEFITS_PAGE: 'Asiakasedut',
  CLIENTSHIP_AND_BENEFITS_INGRESS: '',
  CLAIM_PREMIUM_BENEFITS: 'Lunasta tilaajalahjasi ja käytä asiakasetusi.',
  PREMIUM: 'Tilaajalahja',
  PREMIUM_INGRESS: 'Täällä voit tarkastella tilaajalahjojasi.',
  PREMIUM_CLAIM_REMINDER:
    'Tilaukseesi kuuluu tilaajalahja. Lunastathan sen määräaikaan xx mennessä.',
  NO_PREMIUMS: 'Sinulla ei ole lunastamattomia tilaajalahjoja',
  PREVIOUS_PREMIUMS: 'Aiemmat tilaajalahjat',
  NO_PREVIOUS_PREMIUMS: 'Sinulla ei ole aiempia tilaajalahjoja',
  CTA_CLAIM_PREMIUM: 'Katso ja lunasta asiakasetuja',
  PREMIUM_INVOICE_GROUP_HEADING_PREFIX: 'Tilaus',
  BEST_DEALS: 'Parhaat tutustumistarjoukset',
  CONTINUOUS_SUBSCRIPTION_BENEFITS: 'Jatkuvan tilauksen edut',
  CONTINUOUS_SUBSCRIPTION_BENEFITS_BODY:
    'A-lehtien jatkuvaan tilaukseen kuuluu etuja, kuten tilattavan lehden digiarkisto, josta pääset lukemaan jo ilmestyneiden lehtien sisältöjä. Lisäksi saat valita maksuttoman tutustumisnumeron kerran vuodessa. Huomioithan, että tilauksesi on jatkuva silloin, kun tilauksella ei ole päättymispäivää.',
  CUSTOMER_BENEFITS_RAFFLES_HEADING: 'Arvonnat tilaajien kesken',
  CUSTOMER_BENEFITS_RAFFLES_BODY_SUBSECTION_ONE_HEADING: 'Päävoitto 30.000,00 €',
  CUSTOMER_BENEFITS_RAFFLES_BODY_SUBSECTION_ONE_BODY_PARAGRAPH_ONE:
    'Voittorahoilla voit ostaa vaikka upean, uuden auton tai remontoida kotisi ja uusia sisustuksen tai lähteä vaikkapa matkalle....',
  CUSTOMER_BENEFITS_RAFFLES_BODY_SUBSECTION_ONE_BODY_PARAGRAPH_TWO:
    'Osallistut päävoiton arvontaan %s.',
  CUSTOMER_BENEFITS_RAFFLES_BODY_SUBSECTION_TWO_HEADING: 'Juhlavoitot 1.000,00 €',
  CUSTOMER_BENEFITS_RAFFLES_BODY_SUBSECTION_TWO_BODY_PARAGRAPH:
    'Osallistut joka kuukauden viimeinen arkipäivä 1 000,00 € arvontaan vuoden %s ajan. <a href="/arvonnat">Katso, oletko arvonnan voittaja</a>. Arvonnan voittajalle ilmoitetaan voitosta myös henkilökohtaisesti.',
  MY_INFO: 'Omat tiedot',
  USER_INFO: 'Käyttäjätiedot',
  EDIT_MY_INFO: 'Muokkaa omia tietoja',
  EDIT_IN_IDENTITY_SERVICE: 'Muokkaa A-tunnus-palvelussa',
  CHANGE_PASSWORD: 'Vaihda salasana',
  NEW_PASSWORD_SENT: 'Uusi salasana lähetetty',
  NEW_AUTHENTICATION_HEADING: 'Asiakaspalvelu uudistuu',
  USERNAME: 'Käyttäjätunnus',
  LOGIN_DATA: 'Kirjautumistiedot',
  CREDENTIALS_INFO_INGRESS:
    'Hallitse <b>käyttäjätunnusta</b> ja <b>salasanaa</b>, joilla kirjaudut A-lehtien verkkopalveluihin. Olemme sinuun jatkossa yhteydessä tässä määrittämääsi sähköpostiosoitteeseen tai puhelinnumeroon.',
  CURRENT_PASSWORD: 'Nykyinen salasana',
  NEW_PASSWORD: 'Uusi salasana',
  PASSWORD: 'Salasana',
  DESIRED_PASSWORD: 'Haluamasi salasana',
  VERIFY_PASSWORD: 'Vahvista salasana',
  ACCEPT_TERMS:
    'Hyväksy <a href="https://www.a-lehdet.fi/tietoa-meista/kayttoehdot" target="_blank">käyttöehdot</a>',
  TARGETING_DISCLAIMER:
    'Näkemiäsi sisältösuosituksia ja mainoksia sekä vastaanottamaasi markkinointi- ja asiakasviestintää voidaan personoida verkossa kuluttamiesi sisältöjen ja A-tunnukseen liittyvien tilaustietojesi perusteella. Tunnuksen luotuasi voit vaikuttaa kohdentamiseen A-tunnuksen kohdentamisvalintojen kautta. Evästeisiin perustuvaa mainonnan kohdentamista voit hallita A-lehtien <a href="https://tietosuoja.a-lehdet.fi/asetukset" target="_blank">evästetyökalussa</a>.',
  SIGN_UP: 'Luo tunnus',
  CLAIM_OLD_ACCOUNT: 'Ota käyttöön vanha tunnuksesi',
  REGISTER: 'Rekisteröidy',
  CREATING_ACCOUNT: 'Luodaan tunnus...',
  CREATE_USER_ACCOUNT: 'Luo tunnus',
  CONFIRM: 'Vahvista',
  CONFIRM_MESSAGE_NOT_ARRIVE: 'Jos et saanut vahvistuskoodia',
  CONFIRM_PROGRESS: 'Vahvistetaan...',
  CONFIRM_EMAIL: 'Vahvista sähköpostiosoitteesi',
  CONFIRM_PHONE: 'Vahvista puhelinnumerosi',
  CONFIRM_PASSWORD: 'Vahvista salasanasi',
  CONFIRM_ACCOUNT: 'Vahvista A-tunnuksesi',
  CONFIRM_DELETE: 'Poista',
  CONFIRM_DELETE_INGRESS: 'Haluatko varmasti poistaa sähköpostisi %s tiedoistamme?',
  CONFIRM_INGRESS:
    'Sinulle on lähetetty vahvistuskoodi. Kirjoita saamasi koodi alle.',
  CONFIRM_EMAIL_INGRESS:
    'Antamaasi sähköpostiin on lähetetty vahvistuskoodi. Kirjoita saamasi koodi alle viiden minuutin kuluessa.',
  CONFIRM_PHONE_INGRESS:
    'Antamaasi puhelinnumeroon on lähetetty vahvistuskoodi. Kirjoita saamasi koodi alle viide minuutin kuluessa.',
  CONFIRM_DONT_CLOSE_WARNING: 'HUOM! Ethän sulje tätä välilehteä.',
  CONFIRM_LEAVE_WARNING:
    'Antamaasi sähköpostiin on lähetetty vahvistuskoodi. Käytäthän toista välilehteä tai ikkunaa sähköpostisi avaamiseen. Kirjoita saamasi koodi lomakkeelle viiden minuutin kuluessa.',
  CREDENTIAL_CANT_BE_DELETED_TOOLTIP:
    'Tämä sähköposti toimii käyttäjätunnuksenasi. Voit muokata sitä siirtymällä omista tiedoista A-tunnus-palveluun.',
  EMAIL_OR_PHONE: 'Sähköposti tai puhelinnumero',
  CREDENTIALS_FORM_USERNAME_TOOLTIP:
    'Lähetämme vahvistusviestin antamaasi sähköpostiin tai puhelinnumeroon (tekstiviesti on ilmainen)',
  CREDENTIALS_FORM_PASSWORD_TOOLTIP:
    'Salasanassa pitää olla vähintään kahdeksan merkkiä.',
  CHANGE_NAME: 'Nimenmuutos',
  CHANGE_NAME_SENT: 'Nimenmuutos vastaanotettu',
  CHANGE_NAME_FLASHMSG_INGRESS:
    'Nimenvaihto on nyt käsiteltävänä. Nimenvaihdos tulee näkyviin asiakaspalveluun noin viikon kuluessa.',
  HAS_NAME_CHANGED: 'Onko nimesi muutunut?',
  TEMP_ADDRESS_ENABLE_LABEL: 'Tee tilapäinen osoitteenmuutos',
  TEMP_ADDRESS_HEADING: 'Tilapäinen osoite',
  TEMP_ADDRESS_INGRESS:
    'Jos haluat muuttaa osoitettasi tilapäisesti esim. lomasi ajaksi, ilmoitathan siitä viimeistään 14 vrk etukäteen.',
  TEMP_ADDRESS_DISABLED: 'Tilapäinen osoite ei ole voimassa.',
  TEMP_ADDRESS_ENABLED_WARNING: 'Huom! Tilapäinen osoitteenmuutos voimassa %s - %s',
  MARKETING_PERMISSION: 'Luvat',
  MARKETING_PERMISSION_INGRESS:
    '<p>Haluamme, että koet sisältömme ja mainonnan sinulle ajankohtaiseksi. Voit muuttaa valintojasi milloin tahansa A-lehtien <a href="https://tietosuoja.a-lehdet.fi/asetukset" target="_blank">evästetyökalussa</a>. Evästetyökalun valinnoilla vaikutetaan sisällön henkilökohtaisuuteen. Valinnoilla ei estetä mainontaa.</p><p>Sähköpostiin liittyviä markkinointilupia pääset muokkaamaan sähköposteista löytyvien linkkien kautta.</p>',
  MARKETING_PERMISSION_SMS_LABEL: 'Tekstiviestit',
  MARKETING_PERMISSION_SMS_DESCRIPTION:
    'Haluan saada ilmoituksia ja tarjouksia myös tekstiviesteinä.',
  MARKETING_PERMISSION_TARGETING_ACCEPT_LABEL:
    'Haluan henkilökohtaisesti kohdennettua sisältöä ja mainontaa, jolloin näen parhaat tarjoukset!',
  MARKETING_PERMISSION_TARGETING_ACCEPT_DESCRIPTION:
    'Näkemiäni sisältösuosituksia ja mainoksia sekä vastaanottamaani markkinointi- ja asiakasviestintää voidaan personoida verkossa kuluttamieni sisältöjen ja A-tunnukseen liittyvien tilaustietojeni perusteella. Ilman tätä valintaa, näen saman määrän tuotesuositteluja ja muuta mainontaa, mutta nämä viestit eivät välttämättä palvele juuri minua.',
  MARKETING_PERMISSION_TARGETING_DENY_LABEL:
    'En halua henkilökohtaisesti kohdennettua sisältöä, mainontaa tai viestintää.',
  MARKETING_PERMISSION_TARGETING_DENY_DESCRIPTION:
    'Näen yhtä paljon mainontaa kuin aiemmin, mutta se ei ole kohdennettu A-tunnukseen liittyvien tietojen perusteella. Vastaanotan yhä markkinointi- ja asiakasviestejä, mutta ne eivät ole kohdennettu verkossa kuluttamieni sisältöjen perusteella.',
  ADD_INFO: 'Täydennä tietosi',
  ADD_INFO_INGRESS:
    'Asiakasnumerosi löydät tilausvahvistuksesta, lehden takakannesta osoitetietojesi yhteydestä tai laskusta. Lisäämällä asiakasnumerosi näet tilauksesi.',
  CUSTOMER_NUMBER_LOCATION:
    'Asiakasnumeron mukaan näytämme tilaamasi lehdet. Löydät sen tilausvahvistuksesta, lehden takakannesta osoitetietojesi yhteydestä tai laskusta.',
  CONTACT_INFO: 'Nimi- ja osoitetiedot',
  ADDRESS_INGRESS:
    'Kun osoitteesi muuttuu, riittää että teet osoitteenmuutoksen Postiin. Lehti tulee näin automaattisesti uuuteen osoitteeseen.',
  GIFT_MAGAZINE_HEADING: 'Lahjalehti ilmaiseksi',
  GIFT_MAGAZINE_INGRESS:
    'Saat asiakasetuna valita lahjalehden laadukkaasta lehtivalikoimastamme kerran vuodessa ilmaiseksi. Muistutuksen ja ohjeet saat laskun mukana.',
  CLAIM: 'Lunasta',
  NEED_HELP: 'Tarvitsetko apua?',
  NEED_HELP_INGRESS:
    'Lomakkeiden käsittely kestää noin viikon. Nopeammin hoidat asian kirjautumalla nettiasiakaspalveluun tai chatin kautta.',
  PRINT_GIFTCARD: 'Lahjakortin tulostus',
  GIFTCARD_INGRESS:
    'Valitse lahjakortin malli, täytä korttiin tulevat tiedot ja tulosta tai lähetä kortti sähköpostilla.',
  GIFTCARD_EMAIL_FLASH_HEADING: 'Lahjakortti lähetetty sähköpostitse',
  REGISTRY_INFO: 'Rekisteritiedot',
  CHECK_REGISTRY_INGRESS:
    'Voit tarkistaa, mitä tietoja sinusta on tallennettuna A-lehtien rekisteriin.',
  CHECK_REGISTRY: 'Tarkista rekisteritietosi',
  ACCOUNT_SETTINGS: 'Tilin asetukset',
  OFFER_ORDER_CTA: 'Osta',
  NO_PHONENUMBER_SET: 'Ei puhelinnumeroa',
  NO_EMAIL_SET: 'Ei sähköpostiosoitetta',
  BILLING: 'Laskutus',
  BILLING_LINK: 'Laskutustietojen hallinta',
  BACK_TO_USERINFO: 'Palaa omiin tietoihin',
  LINK_TO_E_MAGAZINES: 'Lue digilehtiä',
  RAFFLES_HEADING: 'Arvonnat',
  RAFFLES_INGRESS: '',
  CHAT_INFO_HEADING: 'A-lehtien asiakaspalvelu chat',
  CHAT_INFO_INGRESS: '',
  SUBSCRIBERS_HEADING: 'Tilaajille',
  IMPERSONATION_START_FLASH_TITLE: 'Käyttö asiakkaana %s aloitettu',
  IMPERSONATION_START_FLASH_BODY:
    'Käytät järjestelmää nyt asiakkaan %s (%s, asiakasnumero %s) puolesta.',
  IMPERSONATION_END_FLASH_TITLE: 'Käyttö asiakkaana lopetettu',
  IMPERSONATION_END_FLASH_BODY:
    'Käytät järjestelmää taas omalla käyttäjätunnuksellasi.',
  IMPERSONATION_START_USER_NOT_FOUND_TITLE: 'Käytön aloitus epäonnistui',
  IMPERSONATION_START_USER_NOT_FOUND_BODY: 'Käyttäjätunnusta %s ei ole olemassa.',
  IMPERSONATION_START_OR_END_ERROR_TITLE: 'Tapahtui virhe',
  IMPERSONATION_START_OR_END_ERROR_BODY: 'Jotain meni vikaan. Yritä uudestaan.',
  ACCOUNT_CREATED_TITLE: 'Tunnus luotu!',
  ACCOUNT_CREATED_SUBTITLE_ONE:
    'A-tunnus on nyt luotu. Voit hallita sisällön, mainonnan ja viestinnän <a href="https://asiakaspalvelu.a-lehdet.fi" target="_blank">kohdentamisvalintoja</a> A-lehtien sähköisessä asiakaspalvelussa omien tietojen kautta. Evästeisiin perustuvaa mainonnan kohdentamista voit hallita A-lehtien <a href="https://tietosuoja.a-lehdet.fi/asetukset" target="_blank">evästetyökalussa</a>.',
  ACCOUNT_CREATED_SUBTITLE_TWO:
    'Mikäli olet entuudestaan A-lehtien asiakas ja käyttänyt rekisteröitymiseen samaa sähköpostiosoitetta kuin nyt, tuomme palveluun tietosi valmiiksi syötettynä. Tietoja voit hallinnoida <a href="https://asiakaspalvelu.a-lehdet.fi/" target="_blank">A-lehtien asiakaspalvelusta</a>.',
  ACCOUNT_CREATED_BUTTON_CONTINUE: 'Jatka',
  RELEASE_CUSTOMER_NUMBER_BUTTON: 'Poista asiakasnumerosi',
  RELEASE_CUSTOMER_NUMBER_CONFIRM:
    'Oletko varma? Haluatko varmasti poistaa asiakasnumerosi?',
  SSO_CALLBACK_PAGE_TITLE: 'Kirjaudutaan sisään',
  LOGIN_FOOTER_BUSINESS:
    'Palvelemme A-lehtien sähköisessä asiakaspalvelussa kuluttaja-asiakkaita. Jos asiasi koskee yritysasiakkuutta, ota yhteyttä asiakaspalveluumme.',
  LOGIN_FOOTER_ADS:
    'Koskeeko asiasi mainosvarauksia? Ota yhteyttä A-lehtien yritysasiakasmyyntiin <a href="https://www.a-lehdet.fi/yrityksille" target="_blank">tästä</a>.'
}

export default user
