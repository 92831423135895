import gql from 'graphql-tag'

export const subscriptionFields = gql`
  fragment SubscriptionFields on Subscription {
    id
    role
    productCode
    productTitle
    productImageUrl
    isShippedToBillingAddress
    shipToName
    pricingText
    termLengthText
    orderDate
    nextShipDate
    periodStartDate
    isLocked
    isActive
    digitalMagazineUrl
    isDigitalMagazine
    # isAutoRenewed
    isCreditCardCharged
    subscriptionStatusText
    processingStatusText
    extraContent {
      id
    }
    activeInvoiceId # the invoice number
    editOptions {
      code
    }
    suspendOptions {
      code
      pricingText
      pricingTotalText
      periodEndDate
      freeItems {
        productCode
        productName
        imageUrl
      }
    }
    redirectUrl
    freePremiumUrl
  }
`
