// All the routes in the app in a central place. Some routes can take parameters which
// is why functions are used. All values are functions in order to remove guesswork
// and unnecessary conditionals when using the routes. Route parame

export const routes = {
  home: () => '/',
  dashboard: () => '/minun-tili',
  login: () => '/kirjaudu',
  registrationComplete: () => '/tunnus-luotu',
  changeName: () => '/omat-tiedot/muuta-nimitietojasi',
  userInfo: () => '/omat-tiedot',
  subscriptions: () => '/omat-tilaukset',
  customerBenefits: () => '/asiakkuus-ja-edut',
  billing: () => '/maksaminen-ja-laskutus',
  invoice: (invoiceId = ':invoiceId') => `/laskun-tiedot/${invoiceId}`,
  paymentRoot: () => `/maksa`,
  paymentFlowInvoice: (invoiceId = ':itemRef') => `/maksa/lasku/${invoiceId}`,
  paymentFlowCancelSubscription: (
    subscriptionId = ':itemRef',
    invoiceId = ':invoiceId'
  ) => `/maksa/peruutus/${subscriptionId}/${invoiceId}`,
  moveDueDate: (invoiceId = ':invoiceId') =>
    `/laskun-tiedot/${invoiceId}/siirra-erapaivaa`,
  endedSubscriptions: () => '/paattyneet-tilaukset',
  pauseSubscription: (subscriptionId = ':subscriptionId') =>
    `/tauko-lehtitilaukseen/${subscriptionId}`,
  changeProduct: (subscriptionId = ':subscriptionId') =>
    `/vaihda-lehti-toiseen/${subscriptionId}`,
  changeReceiver: (subscriptionId = ':subscriptionId') =>
    `/vaihda-lehden-saaja/${subscriptionId}`,
  enableAutorenewal: (subscriptionId = ':subscriptionId') =>
    `/muuta-automaattisesti-jatkuvaksi/${subscriptionId}`,
  cancelSubscription: (subscriptionId = ':subscriptionId') =>
    `/peruuta-tilaus/${subscriptionId}`,
  cancelAgreement: (agreementId = ':agreementId') =>
    `/peruuta-digitilaus/${agreementId}`,
  help: () => '/tarvitsetko-apua',
  raffles: () => '/arvonnat',
  chatInfo: () => '/chat',
  subscribers: () => '/tilaajille',
  giftcards: () => '/lahjakortit',
  sso: () => '/sso',
  smsConfirmation: (code = ':code') => `/vahvista/${code}`,
  paymentConfirmation: () => `/maksuvahvistus`,
  creditCardUpdateConfirmation: () => `/luottokorttivahvistus`,
  creditCardUpdateLanding: () => `/luottokortin-paivitys`
}
