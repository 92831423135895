import * as React from 'react'
import * as moment from 'moment'
import * as get from 'lodash/get'
import { withRouter } from 'react-router'
import * as css from '$src/shared/styles/Form.css'
import * as layoutCss from '$src/shared/layout/Layout.css'
import Title from '$src/shared/components/Title'
import UserInfoForm from '../forms/UserInfoForm'
import TemporaryAddressForm from '$src/shared/forms/TemporaryAddressForm'
import changeTempAddress from '../mutations/changeTempAddress'
import { Text } from '$src/shared/content/text'
import { formatDate } from '$src/shared/helpers/formatDate'
import SubTitle from '$src/shared/components/SubTitle'
import Layout, { SidebarAdType } from '$src/shared/layout/Layout'
import MarketingPermissionsForm from '$src/user/forms/MarketingPermissionsForm'
import Button from '$src/shared/components/Button'
import CustomerNumberForm from '../forms/CustomerNumberForm'
import { Customer } from '../types/Customer'
import IdentityFormFields from '$src/shared/forms/IdentityFormFields'
import { ViewerContext } from '$src/shared/helpers/withViewer'
import { MdCheckCircle } from 'react-icons/lib/md'
import { stringify as stringifyQueryString } from 'query-string'
import Tooltip from '$src/shared/components/Tooltip'
import { routes } from '$src/routes'
import FlashMessageFromRouter, {
  FlashMessageQueryProperties
} from '$src/shared/components/FlashMessageFromRouter'

export enum UserInfoFormOptions {
  none = 'NONE',
  userInfo = 'USER_INFO',
  temporaryAddress = 'TEMP_ADDRESS',
  customerNumber = 'CUSTOMER_NUMBER',
  marketingConsent = 'MARKETING_CONSENT'
}

type State = {
  enabledForm: UserInfoFormOptions
}

type Props = {
  queryResult: { customer: Customer }
  history: any
  location: any
  match: any
}

class UserInfo extends React.Component<Props, State> {
  state: State = {
    enabledForm: UserInfoFormOptions.none
  }

  setEnabled = (which = UserInfoFormOptions.none) => {
    this.setState({
      enabledForm: which
    })
  }

  render() {
    const { enabledForm } = this.state
    const {
      queryResult: { customer: user },
      history
    } = this.props

    const temporaryAddress = get(user, 'temporaryAddress') || {
      startDate: '',
      endDate: '',
      isEnabled: false,
      street: '',
      postalCode: '',
      city: '',
      countryCode: ''
    }

    const primaryAddress = get(user, 'primaryAddress') || {
      street: '',
      postalCode: '',
      city: '',
      countryCode: ''
    }

    const temporaryAddressStart = moment(temporaryAddress.startDate)
    const temporaryAddressEnd = moment(temporaryAddress.endDate)

    const temporaryAddressIsEnabled =
      temporaryAddress.isEnabled && moment().isBefore(temporaryAddressEnd, 'day')

    const temporaryAddressEnabledNotice = temporaryAddressIsEnabled ? (
      <p style={{ marginBottom: 'var(--margin)' }} className={css.noticeRed}>
        <strong>
          <Text
            text="TEMP_ADDRESS_ENABLED_WARNING"
            args={[
              formatDate(temporaryAddressStart),
              formatDate(temporaryAddressEnd)
            ]}
          />
        </strong>
      </p>
    ) : null

    const displayInvoiceMethod = user.invoiceMethod || user.latestInvoiceMethod

    return (
      <ViewerContext.Consumer>
        {({ viewer }) => (
          <Layout
            main
            sidebars={{ topOffset: 104, adType: SidebarAdType.LOGGED_IN }}>
            <Title
              title={Text('MY_INFO')}
              breadcrumb={Text('USER_INFO_AND_SERVICE')}
            />
            <FlashMessageFromRouter />
            <div className={layoutCss.constrained}>
              <SubTitle
                title={Text('USERNAME')}
                text={Text('CREDENTIALS_INFO_INGRESS')}
              />
              <IdentityFormFields enabled={false} identity={viewer} />
              <Button
                href={process.env.REACT_APP_IDENTITY_SERVICE_URL}
                target="_blank">
                <Text text="user.EDIT_IN_IDENTITY_SERVICE" />
              </Button>
              <hr className={css.light} />
              <SubTitle
                title={Text('CUSTOMER_NUMBER')}
                text={Text('CUSTOMER_NUMBER_LOCATION')}
              />
              <CustomerNumberForm
                customerId={user.id}
                customerNumber={get(user, 'customerNumbers[0]')}
                isAdmin={user.isAdmin}
                onEnable={() => this.setEnabled(UserInfoFormOptions.customerNumber)}
                onCancel={() => this.setEnabled(UserInfoFormOptions.none)}
                onCompleted={({ changeCustomerNumber }) => {
                  window.scrollTo(0, 0)
                  setTimeout(() => {
                    history.push({
                      pathname: routes.userInfo(),
                      search: `?${stringifyQueryString({
                        viesti:
                          FlashMessageQueryProperties.customerNumberChangeSuccess
                      })}`
                    })
                  })
                }}
                enabled={enabledForm === UserInfoFormOptions.customerNumber}
              />
              <hr className={css.light} />
              <SubTitle
                title={Text('CONTACT_INFO')}
                text={Text('ADDRESS_INGRESS')}
              />
              <UserInfoForm
                street={primaryAddress.street}
                postalCode={primaryAddress.postalCode}
                city={primaryAddress.city}
                countryCode={primaryAddress.countryCode}
                firstNameOrCompanyName={user.firstName || user.companyName}
                lastNameOrDepartment={user.lastName || user.department}
                onCompleted={() => this.setEnabled(UserInfoFormOptions.none)}
                onCancel={() => this.setEnabled(UserInfoFormOptions.none)}
                onEnable={() => this.setEnabled(UserInfoFormOptions.userInfo)}
                enabled={enabledForm === UserInfoFormOptions.userInfo}
              />
              <hr className={css.light} />
              <SubTitle
                title={Text('TEMP_ADDRESS_HEADING')}
                text={Text('TEMP_ADDRESS_INGRESS')}
              />
              {temporaryAddressEnabledNotice}
              <TemporaryAddressForm
                namePrefix="temporary_"
                mutation={changeTempAddress}
                address={temporaryAddress}
                onCompleted={() => this.setEnabled(UserInfoFormOptions.none)}
                onEnable={() =>
                  this.setEnabled(UserInfoFormOptions.temporaryAddress)
                }
                onCancel={() => this.setEnabled(UserInfoFormOptions.none)}
                hideIfDisabled={!temporaryAddressIsEnabled}
                enabled={enabledForm === UserInfoFormOptions.temporaryAddress}
                enableLabel={Text('TEMP_ADDRESS_ENABLE_LABEL')}
              />
              <hr className={css.light} />
              <SubTitle title={Text('user.BILLING')} />
              {/* THIS IS A PLACEHOLDER FOR TESTING */}
              <div
                style={{
                  display: 'flex',
                  width: '100%',
                  justifyContent: 'flex-start',
                  alignItems: 'flex-start',
                  marginBottom: '1rem'
                }}>
                {/* Also testing an _interesting_ way for a quick "switch" statement */}
                <MdCheckCircle size={27} className={css.green} />
                <div style={{ minHeight: '1rem', padding: '0 1rem' }}>
                  <b>
                    {displayInvoiceMethod
                      ? {
                          Email: 'Sähköpostilasku osoitteeseen',
                          Elasku: 'Lähetämme laskun suoraan verkkopankkiisi',
                          Netposti: 'Toimitamme laskun OmaPostiin',
                          Paper: 'Saat paperilaskun postitse'
                        }[displayInvoiceMethod]
                      : null}
                  </b>
                  <div>
                    {displayInvoiceMethod === 'Email'
                      ? user.invoiceEmailAddress
                      : null}
                  </div>
                </div>
                <div style={{ marginTop: 2 }}>
                  <Tooltip
                    message={
                      'Voit muuttaa laskun toimitustapaa laskutustietojen hallinnasta'
                    }
                  />
                </div>
              </div>
              {/* END PLACEHOLDER FOR TESTING */}
              <Button type="link" to={routes.billing()} target="_blank">
                <Text text="user.BILLING_LINK" />
              </Button>
              <hr className={css.light} />
              <SubTitle
                title={Text('MARKETING_PERMISSION')}
                text={Text('MARKETING_PERMISSION_INGRESS')}
              />
              <MarketingPermissionsForm
                customer={user}
                enabled={true}
                {...user.marketingPermissions}
              />
              {/* <hr className={css.light} />

              <SubTitle
                title={Text('REGISTRY_INFO')}
                text={Text('CHECK_REGISTRY_INGRESS')}
              />
              <Button>
                <Text text="CHECK_REGISTRY" />
              </Button> */}
            </div>
          </Layout>
        )}
      </ViewerContext.Consumer>
    )
  }
}

export default withRouter(UserInfo)
