import * as React from 'react'
import * as css from './SubscriptionProduct.css'
import CreditCardExpirationNotice from '../../billing/components/CreditCardExpirationNotice'
import { Subscription, SubscriptionRole } from '../types/Subscription'
import { MdCheckCircle } from 'react-icons/lib/md'
import { formatDate } from '$src/shared/helpers/formatDate'
import { Text } from '$src/shared/content/text'
import classnames from 'classnames'
import { Customer } from '$src/user/types/Customer'
import { store as authStore } from '$src/shared/helpers/authentication'
import { customerDisplayName } from '$src/user/util'

type Props = {
  subscription: Subscription
  productOnly?: boolean
  basicOnly?: boolean
  compact?: boolean
  customer: Customer
}

export default ({
  subscription,
  productOnly = false,
  compact = false,
  basicOnly = false,
  customer
}: Props) => {
  const { id: customerId, creditCard } = customer
  const compactify = (className) => classnames(className, compact ? css.compact : '')
  const isGiftSubscriptionRecipient = subscription.role === SubscriptionRole.S
  const isGiftSubscriptionBuyer = subscription.role === SubscriptionRole.M

  const accessToken = authStore.userAuth.accessToken
  const digitalMagazineUrl =
    process.env.REACT_APP_DIGITAL_MAGAZINES_URL +
    `?m=${subscription.productCode}&t=${accessToken}`
  const digitalArchiveUrl =
    process.env.REACT_APP_DIGITAL_MAGAZINES_URL + `?t=${accessToken}`
  const subscriptionReceiverText = Text(
    'subscriptions.RECEIVER',
    subscription.shipToName || customerDisplayName(customer)
  )

  return (
    <div key={subscription.id} className={compactify(css.gridItem)}>
      <div className={compactify(css.sectionLeft)}>
        <img src={subscription.productImageUrl} alt={subscription.productTitle} />
      </div>
      <div className={compactify(css.sectionMiddle)}>
        <h3 className={css.title}>{subscription.productTitle}</h3>
        {productOnly ? (
          <span>{subscriptionReceiverText}</span>
        ) : (
          <div className={basicOnly ? css.lightContent : ''}>
            <span>{subscriptionReceiverText}</span>
            {subscription.nextShipDate && (
              <span>
                <Text
                  text="subscriptions.NEXT_SHIPPING_DATE"
                  args={[formatDate(subscription.nextShipDate)]}
                />
              </span>
            )}
            {!basicOnly && (
              <>
                <span>{subscription.subscriptionStatusText}</span>
              </>
            )}
            <span>
              <Text
                text="subscriptions.PERIOD_START_DATE"
                args={[formatDate(subscription.periodStartDate)]}
              />
            </span>
            {!isGiftSubscriptionRecipient &&
              subscription.pricingText && (
                <span>
                  <Text text="subscriptions.PRICING" /> {subscription.pricingText}
                </span>
              )}
            {!basicOnly &&
              subscription.isCreditCardCharged &&
              creditCard && (
                <div className={css.creditCardExpirationContainer}>
                  <CreditCardExpirationNotice
                    card={creditCard}
                    viewerId={customerId}
                  />
                </div>
              )}
            {!basicOnly &&
              subscription.processingStatusText && (
                <span className={css.message}>
                  {subscription.processingStatusText}
                </span>
              )}
          </div>
        )}
      </div>
      {!basicOnly && (
        <div className={css.sectionBottom}>
          {(subscription.extraContent.length > 0 ||
            subscription.isDigitalMagazine) && (
            <ul className={css.extraItems}>
              {subscription.extraContent.map((item, itemIndex) => (
                <li key={`extra_item_${itemIndex}`}>
                  <MdCheckCircle />
                  <b>{item.id}</b>
                </li>
              ))}
              {subscription.isDigitalMagazine &&
                !isGiftSubscriptionBuyer && (
                  <li key={`extra_item_digimagazine`}>
                    <MdCheckCircle />
                    <a href={digitalMagazineUrl}>
                      Lue {subscription.productTitle} -digilehti
                    </a>{' '}
                    | <a href={digitalArchiveUrl}> Digiarkisto </a>
                  </li>
                )}
            </ul>
          )}
        </div>
      )}
    </div>
  )
}
